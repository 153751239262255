body {
  padding-top: 310px; /* Adjust padding to avoid content being hidden under the fixed navbar */
}

/* App.css */
.navbar {
  background-color: #333;
  border: none;
}

.navbar a {
  color: #fff;
  padding: 15px;
  text-decoration: none;
}

.navbar a:hover {
  background-color: #555;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navbar-collapse {
  display: flex;
  justify-content: center;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.navbar-nav .nav-link {
  margin-right: 0 !important;
}

.section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

/* Add styles for the Image Slider */
.slick-slider {
  width: 80%;
  margin: auto;
}

.slick-prev,
.slick-next {
  font-size: 24px;
  color: #333;
}

.slick-prev:hover,
.slick-next:hover {
  color: #555;
}

/* Add styles for the Image Gallery */
.gallery {
  padding: 80px 0;
}

.gallery h2 {
  font-size: 36px;
  font-weight: bold;
}

.gallery-item {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

.gallery-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.gallery-item img {
  width: 100%;
  border-radius: 10px;
}
